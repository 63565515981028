.auction-type-selector {
  font-family: Arial, sans-serif;
}

.checkbox-buttons-container {
  display: flex;
}

fieldset {
  border: 1px solid grey;
  border-radius: 7px;
  width: 100%;
}

legend {
  background-color: #07E;
  color: #fff;
  padding: 3px 11px;
  border-radius: 5px;
  float: none;
  margin: auto;
  width: initial;
}

.auction-item {
  padding: 10px;
}

/* Container for the toggle */
.toggle-switch {
  position: relative;
  display: inline-flex; /* Align items horizontally */
  align-items: center; /* Center vertically */
  gap: 10px; /* Space between the toggle and label text */
  cursor: pointer;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.square .slider {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 4px;
}

.square .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

.square input:checked + .slider {
  background-color: #0d6efd;
}

.square input:checked + .slider:before {
  transform: translateX(30px);
}

.label-text {
  font-size: 16px;
  color: #333;
  white-space: nowrap; /* Prevent text wrapping */
}
