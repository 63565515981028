.scrollToTopButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.scrollToTopButton:hover {
  transform: scale(1.1);
}

/* Animation for height expansion */
.clicked {
  animation: expandHeight 0.6s ease-out, fireEffect 0.6s ease-out;
}

/* Height expansion animation */
@keyframes expandHeight {
  0% {
    height: 50px;
  }
  50% {
    height: 80px;
  }
  100% {
    height: 50px;
  }
}

/* Fire effect using ::after pseudo-element */
.scrollToTopButton::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 10px;
  height: 10px;
  background: radial-gradient(circle, #ff4500 0%, transparent 70%);
  opacity: 0;
  border-radius: 50%;
  transition: opacity 0.3s ease;
}

.clicked::after {
  opacity: 1;
  animation: fireEffect 0.6s ease-out;
}

/* Fire effect animation */
@keyframes fireEffect {
  0% {
    transform: translateX(-50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) scale(1.2);
    opacity: 0;
  }
}
